import { AjaxRenderingEngine } from '@scripts/ajax-render-engine';
import '@scripts/ViewModels/variant/ajax-variant-selector.js';

export default new (function ($) {
    'use strict';

    this.vm = function (id) {
        var self = this;
        self.id = "#" + id;
 
        self.service = new WebApiService("FieldRenderer");        
        self.vs = new mcWeb.ajaxVariantSelector.vm(self);        
        self.avsInitialised = false;
        self.load = function(data, settings){                       
          
        };

        self.init = function(data){
            self.currentAreaName = data.areaName;            
            self.currentNodeId = data.nodeId;
            self.width = 400;
            if(self.id === '#lightbox-placeholder'){    
                self.popupEvent = mcWeb.lightbox.events.onShowLightbox;
                self.currentPlacement = "c";
            }
            else {
                self.popupEvent = mcWeb.slideOverBox.events.onShowLightbox;
                self.currentPlacement = "rhs";
            }
        };

        self.show = function( plid, manufacturerId) {
            var param = {
                NodeId: self.currentNodeId,
                Width: self.width,
                ManufacturerId: manufacturerId,
                ActivePlid: plid,
                Placement: self.currentPlacement,
                AreaName : self.currentAreaName                
            };

            self.service.PostAction("RenderQuickview", param).then(
                function(result) {
                    if (result != null && result.Success) {
                        var container = $(self.id);
                        if (container != null && container != undefined) {
                            container.html(result.Content);
                            AjaxRenderingEngine.refineInsertedContent(container, result);
                            //todo: finn riktig klasse å bruke her. avhenger av hvilken popup?
                            //mangler å kjøre royal-slider for resultatet. Må gjøres for å få fram bilder osv.
                            var popupParam = {
                                css: "d4-popup d4-popup-quickview",
                                callback: function () {
                                    var thumbs = {//todo: get from settings?
                                        spacing: 10,
                                        orientation: 'horizontal',
                                        autoCenter: false
                                    };
                                    var navigationMethod = 'bullets';//todo: get from settings?
                                    InitializeRoyalSliderForPreview(null, null, null, 'div.d4-preview', thumbs, navigationMethod);
                                    self.vs.bind();
                                    self.vs.reinit();
                                }
                            }
                            PubSub.publish(self.popupEvent, popupParam);
                        }
                    }
                    else {
                        //hide slideoverbox or lightbox
                        if (self.currentPlacement == "rhs") {
                            PubSub.publish("mcWeb.slideOverBox.onHideLightbox");
                        }
                        else {
                            PubSub.publish("mcWeb.lightbox.onHideLightbox");
                        }
                    }
                })
        };
    };//end vm
})(jQuery);


function InitializeRoyalSliderForPreview(optionChangesXS, optionChangesSM, optionChangesMD, containerClass, thumbnailSettings, navigationMethod) {
    jQuery(function () {

        var optionsXS = {
            controlNavigation: 'bullets',
            keyboardNavEnabled: true,
            numImagesToPreload: 1,
            navigateByClick: false,
            arrowsNav: false,
            imageScaleMode: 'fit',
            thumbs: false,
            video: {
                disableCSS3inFF: false
            },
            fullscreen: {
                enabled: false
            }
        };

        var optionsSM = {
            controlNavigation: 'bullets',
            keyboardNavEnabled: true,
            numImagesToPreload: 1,
            navigateByClick: false,
            imageScaleMode: 'fit',
            thumbs: false,
            video: {
                disableCSS3inFF: false
            },
            fullscreen: {
                enabled: false
            }
        };

        var thumbs = {
            spacing: 10,
            orientation: 'vertical',
            autoCenter: false
        };
        if (thumbnailSettings != undefined && thumbnailSettings != null) {
            thumbs = thumbnailSettings;
        }
        var controlNavigation = 'thumbnails';

        if (navigationMethod != undefined && navigationMethod != null) {
            controlNavigation = navigationMethod;
        }

        var optionsMD = {
            controlNavigation: controlNavigation,
            keyboardNavEnabled: true,
            numImagesToPreload: 1,
            navigateByClick: false,
            imageScaleMode: 'fit',
            thumbs: thumbs,
            video: {
                disableCSS3inFF: false
            },
            fullscreen: {
                enabled: false
            }
        };

        jQuery.extend(optionsXS, optionChangesXS);
        jQuery.extend(optionsSM, optionChangesSM);
        jQuery.extend(optionsMD, optionChangesMD);

        var options = {};
        switch (mcWeb.responsive.getScreenSize()) {
            case 'xs':
                options = optionsXS;
                break;
            case 'sm':
                options = optionsSM;
                break;
            default:
                options = optionsMD;
                break;
        }

        var slider = jQuery(containerClass + " .prod-image-slider");
        var rs = slider.royalSlider(options).data('royalSlider');
        slider.prepend(slider.find('.rsNav'));
    });
}

////Bind viewmodel for two quickviews, one for center and one for rhs
//loadModule(mcWeb.quickview, 'slideover-placeholder', 'slideover-placeholder', null, null,true);
//loadModule(mcWeb.quickview, 'lightbox-placeholder', 'lightbox-placeholder', null, null,true);
