
import McUtils from '@scripts/McUtils';
import Quickview from '@scripts/ViewModels/quickview';

export const AjaxRenderingEngineEventTypes = {
    onFieldContentChanged: "AjaxRenderingEngine.onFieldContentChanged",
    onNewAreasShown: "AjaxRenderingEngine.onNewAreasShown",
    onPopupReady: "AjaxRenderingEngine.onPopupReady",
    onPopupFilterChanged: "AjaxRenderingEngine.onPopupFilterChanged",
    onAjaxRenderingLoaded: "AjaxRenderingEngine.onAjaxRenderingLoaded",
    onKnockoutRenderingCompleted: "AjaxRenderingEngine.onKnockoutRenderingCompleted",
    onPriceRefreshRequested: "AjaxRenderingEngine.onPriceRefreshRequested",
    onPushProductsIntoField: "AjaxRenderingEngine.onPushProductsIntoField",
    onClearFieldFilter: "AjaxRenderingEngine.onClearFieldFilter",
    onFieldsRefresh: "AjaxRenderingEngine.onFieldsRefresh",
    onFilterLoadRequested: "AjaxRenderingEngine.onFilterLoadRequested",
    onAddNewAreaContent: "AjaxRenderingEngine.onAddNewAreaContent",
    onReloadFieldByListingType: "AjaxRenderingEngine.onReloadFieldByListingType"
};

export const AjaxRenderingEngine = new (function AjaxRenderingEngine($) {
    PubSub.subscribe(AjaxRenderingEngineEventTypes.onAddNewAreaContent, function (message, data) {
        jQuery('#A' + data.AreaId).html(data.Content);
        self.updateFields('#A' + data.AreaId +' .ajax-field', "#AttributeListBox", false, false, false, 0);        
    });

    var self = this;
    self.service = new WebApiService("AreaRenderer");
    self.filterService = new WebApiService("AttributeFilter");
    self.fieldRenderService = new WebApiService("FieldRenderer");
    self.statsService = new WebApiService("Stats");
    self.imageService = new WebApiService("ImageRenderer");
    self.deviceSize = "";
    self.currentSlideshow = null;
    self.allowUseOfProductCache = true;
    self.currentField = null;
    self.PreviewProduktLagerId = null;
    self.scrollEventIsRegistered = false;
    self.scrollEventFieldIsRegistered = false;
    self.scrollEventFooterIsRegistered = false;
    self.HasMorePages = false;
    self.PagingMode = 1;
    self.LoadingFromScroll = false;
    self.fieldsReadyToLoad = [];
    self.leftSideFieldsReadyToLoad = [];
    self.lazyLoadFieldSetupDone = false;
    self.footerObserver = null;
    self.IntersectionObserveAllowed = ('IntersectionObserver' in window);
    self.AutoScrolledPages = 0;
    self.InitialPageNumber = 1;
    self.CurrentPageNumber = 1;
    self.HighestPageNumberSeen = 1;
    self.LowestPageNumberSeen = 1;
    self.InitialLoadDone = false;
    self.subscriptionsInitialised = false;
    self.PrevPagingLoaded = false;
    self.myScrollToElement = null;
    self.currentArticleId = 0;
    self.CheckStatistics = false;
    self.ListingTypeGrid = false;
    self.lightBoxIsOpen = false;
    self.slideOverBoxIsOpen = false;
    self.attributeFilterInitiated = false;
    self.rhsQuickview = new Quickview.vm('slideover-placeholder');
    self.centerQuickview = new Quickview.vm('lightbox-placeholder');
    self.currentSearchKeyword = "";
    self.finishedLoadingFieldsAbove = false;
    self.fieldUpdateInProgress = false;
    self.placeholderDict = {};
    self.publishMode = false;
    this.load = function () {
        if (jQuery('body.listtype-grid').length > 0) {
            self.ListingTypeGrid = true;
        }

        jQuery("div.HeaderPublishAreaContainer .load-later").each(function (index, item) {

            jQuery(item).removeClass("load-later");
            jQuery(item).addClass("load-first");
        });

        self.publishMode = ($("body.publish-mode").length > 0);

        var pageParam = self.getParameterByName('pageID');
        if (pageParam.length > 0) {
            self.InitialPageNumber = parseInt(pageParam);
            self.CurrentPageNumber = self.InitialPageNumber;
            self.HighestPageNumberSeen = self.InitialPageNumber;
            self.LowestPageNumberSeen = self.InitialPageNumber;

        }
        var articleIdentifier = jQuery("div#current-article-id");
        if (articleIdentifier.length > 0) {
            self.currentArticleId = jQuery(articleIdentifier[0]).data("current-article");
        }

        self.CheckStatistics = (
            window.D4AiActive !== undefined &&
            window.D4AiActive &&
            window.D4AiRegisterStats !== undefined &&
            window.D4AiRegisterStats);

        var loadFirstParams = {
            targetKey: "div.load-first .ajax-field.not-loaded",
            filterTargetKey: "#AttributeListBox",
            onlyUpdateProductList: false,
            doNotRenderFilter: false,
            useDummyData: false,
            popupFilter: 0,
            nextPageNumber: 0
        };

        var loadSecond = {
            targetKey: "div.load-later .web-pub-field[data-popup='0'] .ajax-field.loaded[data-sf='0'][data-field-is-productlist='1']",
            filterTargetKey: "#AttributeListBox",
            onlyUpdateProductList: true,
            doNotRenderFilter: false,
            useDummyData: false,
            popupFilter: 0,
            nextPageNumber: 0
        };

        var loadLeftSideParams = {
            targetKey: "div.load-left .ajax-field.not-loaded",
            filterTargetKey: "#AttributeListBox",
            onlyUpdateProductList: false,
            doNotRenderFilter: false,
            useDummyData: false,
            popupFilter: 0,
            nextPageNumber: 0
        };


        var loadLaterParams = {
            targetKey: "div.load-later .web-pub-field[data-popup='0'] .ajax-field.not-loaded[data-sf='0']:not(.menu-container div.load-later .web-pub-field[data-popup='0'] .ajax-field.not-loaded[data-sf='0'])",            
            filterTargetKey: "#AttributeListBox",
            onlyUpdateProductList: false,
            doNotRenderFilter: false,
            useDummyData: false,
            popupFilter: 0,
            nextPageNumber: 0
        };

        var loadLaterWithFilterParams = {
            targetKey: "div.load-later .web-pub-field[data-popup='0'] .ajax-field.not-loaded[data-sf='1']:not(.menu-container div.load-later .web-pub-field[data-popup='0'] .ajax-field.not-loaded[data-sf='1'])",
            filterTargetKey: "#AttributeListBox",
            onlyUpdateProductList: false,
            doNotRenderFilter: false,
            useDummyData: false,
            popupFilter: 0,
            nextPageNumber: 0
        };

        var loadPopupParams = {
            targetKey: "div.ajax-field.not-loaded[data-popup='1']",
            filterTargetKey: "#AttributeListBox",
            onlyUpdateProductList: false,
            doNotRenderFilter: false,
            useDummyData: false,
            popupFilter: 1,
            nextPageNumber: 0
        };
        
        if (jQuery(".ProductMenu").css("order") === 1) {
            self.unhideProductMenu();//do not wait for attributes are loaded when order is 1
        }

        self.fieldsToLoad = [];
        self.fieldsToLoad.push(loadFirstParams);
        self.fieldsToLoad.push(loadSecond);
        self.fieldsToLoad.push(loadLeftSideParams);
        self.fieldsToLoad.push(loadLaterParams);
        self.fieldsToLoad.push(loadLaterWithFilterParams);

        self.firstPageViewPopup = loadPopupParams;
        self.updateContentForPreloadedFields();

        self.runUpdateFields();

        self.updateSelectedAttributeListVisibility();
        PubSub.publish(AjaxRenderingEngineEventTypes.onAjaxRenderingLoaded);

        if (jQuery(window).scrollTop() === 0)
            self.finishedLoadingFieldsAbove = true;

    };

    

    this.getQuickview = function (placement) {
        if (placement === "rhs")
            return self.rhsQuickview;
        else return self.centerQuickview;
    };


    this.openQuickView = function (nodeid, plid, manufacturerid, placement, areaname, me) {
        if (me !== undefined) {
            //check if we can use the given placement
            var myCenteredPopup = jQuery(me).closest("#center-popup");
            if (placement == 'c' && myCenteredPopup.length > 0) {
                //the popup would normalle be shown in center, but right now the ad is already shown in center,
                //so lets use the rhs instead
                placement = 'rhs';
            }
            else if (placement === 'rhs' && jQuery(me).closest("#rhs-popup").length > 0) {
                placement = 'c';
            }
        }
        var quickview = self.getQuickview(placement);
        quickview.init({ areaName: areaname, nodeId: nodeid });
        quickview.show(plid, manufacturerid);
    };



    this.loadNextPage = function () {
        self.updateFields("div.load-later .ajax-field.not-loaded[data-field-is-productlist='1']", "#AttributeListBox", true, false, false, 0, true);
    };

    this.loadPrevPage = function () {        

        var prevPageNumber = self.CurrentPageNumber - 1;
        if (prevPageNumber < 1) {
            //we're already on the first page, so we just stop processing
            return;
        }
        self.updateFields("div.load-later .ajax-field.not-loaded[data-field-is-productlist='1']", "#AttributeListBox", true, false, false, 0, prevPageNumber);
    };

    this.reloadPageCallback = function (fieldIds) {//receives array with fieldids to update
        if (self.fieldsToLoad === undefined)
            self.fieldsToLoad = [];

        if (fieldIds == undefined || fieldIds.length == 0) {
            self.updateFields(".ajax-field.not-loaded", "#AttributeListBox", false, false, false, 0, 0);
        } else {
            if (isNaN(fieldIds)) {
                for (var i = 0; i < fieldIds.length; i++) {
                    var params = {
                        targetKey: ".field-container-" + fieldIds[i] + " div.ajax-field",
                        filterTargetKey: "#AttributeListBox",
                        onlyUpdateProductList: false,
                        doNotRenderFilter: false,
                        useDummyData: false,
                        popupFilter: 0,
                        nextPageNumber: 0
                    };
                    if (jQuery(params.targetKey).length > 0)
                        self.fieldsToLoad.push(params);
                }
            } else {
                var params = {
                    targetKey: ".field-container-" + fieldIds + " div.ajax-field",
                    filterTargetKey: "#AttributeListBox",
                    onlyUpdateProductList: false,
                    doNotRenderFilter: false,
                    useDummyData: false,
                    popupFilter: 0,
                    nextPageNumber: 0
                };
                if (jQuery(params.targetKey).length > 0)
                    self.fieldsToLoad.push(params);
            }
            self.runUpdateFields();
        }
    };

    ////moved
    this.reloadAllLinkedFields = function () { 
        getLibrary("renderEngineExtras").then(() => { reloadAllLinkedFieldsExtra(self) });
    };

    //find a single element on the page, ask the server to render it again, and then swap it out for the new one
    this.reloadSingleElement = function (nodeId, activePlid, area, fieldId, element) {  //TODO: MOVE, move to separate publisher-only file
        getLibrary("renderEngineExtras").then(() => {
            reloadSingleElement(self, nodeId, activePlid, area, fieldId, element);
        });
    };

    this.reloadLightboxFields = function () {
        self.updateFields(".modal-content .ajax-field.not-loaded", "#AttributeListBox", false, false, false, 0);
    };

    this.reloadFieldByListingType = function (listingtype) {
        self.reloadFromCallbackActive = true;
        self.updateFields(".ajax-field[data-listing-type=" + listingtype + "]", "#AttributeListBox", false, false, false, 0, 0);
    };
    PubSub.subscribe(AjaxRenderingEngineEventTypes.onReloadFieldByListingType, function (msg, listingType) {
        self.reloadFieldByListingType(listingType);
    });


    this.reloadProductListField = function () {
        self.resetAutoScrolledPages();
        self.updateFields(".ajax-field[data-field-is-productlist='1']", "#AttributeListBox", true, false, false, 0, 0);
    };

    this.reloadInstantSearchPreview = function (plid) {
        self.PreviewProduktLagerId = plid;
        self.updateFields(".InstantSearch .d4-instant-search-loadbyajax .ajax-field",
            "",
            false,
            true,
            false,
            0,
            false);
    }


    this.setListingType = function (listingAsGrid) {
        self.ListingTypeGrid = listingAsGrid;
        if (listingAsGrid) {
            jQuery('body').addClass('.listtype-grid');
            jQuery('body').removeClass('.listtype-list');
            var fieldContentContainer = jQuery('.ajax-field.inner-layout-container[data-area-id="CenterContentDynamicProdListAlternate"]');
            var fieldContainer = fieldContentContainer.closest(".web-pub-field");
            var fieldSorters = fieldContainer.children(".FieldSorter");
            if (fieldSorters.length > 0) {
                fieldSorters.each(function (index, item) {
                    $(item).html('');
                });
            }
            jQuery('.ajax-field.inner-layout-container.not-loaded[data-area-id="CenterContentDynamicProdListAlternate"]').html('');
        } else {
            jQuery('body').addClass('.listtype-list');
            jQuery('body').removeClass('.listtype-grid');
            var fieldContentContainer = jQuery('.ajax-field.inner-layout-container[data-area-id="CenterContentDynamicAdList"]');
            var fieldContainer = fieldContentContainer.closest(".web-pub-field");
            var fieldSorters = fieldContainer.children(".FieldSorter");
            if (fieldSorters.length > 0) {
                fieldSorters.each(function (index, item) {
                    $(item).html('');
                });
            }
            jQuery('.ajax-field.inner-layout-container[data-area-id="CenterContentDynamicAdList"]').html('');
        }

        var newUrl = self.getCurrentFirstPage();
        self.CurrentPageNumber = 1;
        window.history.pushState(newUrl, '', newUrl);
    };

   
    this.SetAllowedUseOfProductCache = function (val) {
        self.allowUseOfProductCache = val;
    };

    this.reloadPageListingOnlyCallback = function (skipFilter) {
        self.reloadFromCallbackActive = true;
        var doSkipFilter = true;
        if (skipFilter !== undefined)
            doSkipFilter = skipFilter
        self.updateFields(".ajax-field[data-field-is-productlist='1']", "#AttributeListBox", true, doSkipFilter, false, 0);
    };


    this.reloadForPublisher = function (newDeviceSize) {
        if (newDeviceSize)
            self.deviceSize = newDeviceSize;

        self.updateFields(".ajax-field.not-loaded", "#AttributeListBox", false, false, false, 0);
    };

    this.reloadFields = function (parentIdentifier) {
        self.addRemainingFieldsToDom();
        self.reloadFromCallbackActive = true;
        self.updateFields(parentIdentifier + " .ajax-field", "#AttributeListBox", false, false, false, 0, 0);
    };

    this.ChangeSortType = function (dropDownControl) {
        var cont = jQuery(dropDownControl);

        McAjaxRequest('/WebPages/Extras/SessionStateApi.aspx', 'ChangeSort', cont.val(), self.reloadPageCallback);
    };

    this.ToggleGroupVariants = function (obj) {
        getLibrary("renderEngineExtras").then(() => { toggleGroupVariants(self, obj); });        
    };

    this.ToggleStockMode = function (obj) {
        getLibrary("renderEngineExtras").then(() => { toggleStockMode(self, obj); });       
    };

    this.ToggleStockModeFjernlager = function (obj) {
        getLibrary("renderEngineExtras").then(() => { toggleStockModeFjernlager(self, obj); });        
    };

    this.getParameterByName = function (name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }


    this.addFieldToArea = function (areaName, fieldId, nodeId) {//todo: move to publisher script
        getLibrary("renderEngineExtras").then(() => { addFieldToAreaExtra(self, areaName, fieldId, nodeId); });       
    };
    this.replaceField = function (fieldId, nodeId, isEdm) {
        getLibrary("renderEngineExtras").then(() => { replaceFieldExtra(self, fieldId, nodeId, isEdm); });        
    };

    $.fn.scrollView = function (offset) {
        return this.each(function () {
            if (offset == undefined)
                offset = 0;

            $('html, body').animate({
                scrollTop: $(this).offset().top + offset
            }, 1000);
        });
    }

    this.getCurrentFirstPage = function () {
        getLibrary("renderEngineExtras").then(() => { RemovePageNumberFromLink(document.location.href); });
    }
    
    this.addValueToQueryString = function (me, attributeId, attributeValueId) {
        getLibrary("renderEngineExtras").then(() => { addValueToQueryStringExtra(self, me, attributeId, attributeValueId); }); 
    };
   

    this.updateSelectedAttributeListVisibility = function () {
        var searchLoc = decodeURIComponent(self.getParameterByName('Filter'));
        if (searchLoc.length <= 0) {
            jQuery("div.SelectedAttributeBox .filter-selection-container").addClass("hide");
        }
        else {
            jQuery("div.SelectedAttributeBox .filter-selection-container").removeClass("hide");
        }
    };
  

    this.doScrollToNextPage = function (element) {
        self.resetAutoScrolledPages();
        return self.scrollToNextPage(element);
    };


    this.scrollToNextPage = function (element) {
        getLibrary("renderEngineExtras").then(() => { scrollToNextPageExtra(self, element); }); 
        return false;
    };
   

    this.navigateToPage = function (element) {
        try {
            var url = element.getAttribute("href");
            var title = element.getAttribute("title");
            window.history.pushState(url, document.title + ' - ' + title, url);
            scrollViewToTopOfPage(500);
            self.updateFields(".ajax-field", "#AttributeListBox", true, true, false, 0); //todo: check that we're actually only changing page, because otherwise we need to set the param doNotRenderFilter to false
            return false; //indicate navgiation is complete
        } catch (e) {
            return true; //must navigate using the link
        }
    };

  

    this.fetchAttributeValues = function (attributeId, target, targetId, nodeId, fieldId) {
        getLibrary("renderEngineExtras").then(() => { fetchAttributeValuesExtra(self, attributeId, targetId, nodeId, fieldId); }); 
    };
    

    this.hidePaging = function () {
        var pagingTag = jQuery('.field-paging-container');
        jQuery(pagingTag).addClass('hidden');
    };
    this.showPagingContainer = function () {
        var pagingTag = jQuery('.field-paging-container');
        jQuery(pagingTag).removeClass('hidden');
    };

    this.showPageLoadingIcon = function () {
        var pagingTag = jQuery('.field-paging-next');
        jQuery(pagingTag).addClass('hidden');
        var loadingTag = jQuery('.field-paging-loading');
        jQuery(loadingTag).removeClass('hidden');
    };

    this.hidePageLoadingIcon = function () {
        var pagingTag = jQuery('.field-paging-next');
        jQuery(pagingTag).removeClass('hidden');
        var loadingTag = jQuery('.field-paging-loading');
        jQuery(loadingTag).addClass('hidden');
    };

    this.runUpdateFields = function () {
        if (self.fieldsToLoad != undefined && self.fieldsToLoad.length > 0) {
            var param = self.fieldsToLoad.shift();
            self.updateFields(param.targetKey,
                param.filterTargetKey,
                param.onlyUpdateProductList,
                param.doNotRenderFilter,
                param.useDummyData,
                param.popupFilter,
                param.nextPageNumber);
        }
    };


    this.refreshPriceCache = function () {
        self.service.PostAction("ClearPriceCaches").then(function (result) {

        });
    };

    this.updateFields = function (targetKey, filterTargetKey, onlyUpdateProductList, doNotRenderFilter, useDummyData, popupFilter, nextPageNumber) {
        //todo: vis venteikon
        var fieldsToLoad = $(targetKey);
        var fieldsArray = [];
        var queryFilterParam = self.getParameterByName("Filter");
        var queryMinParam = self.getParameterByName("Min");
        var queryMaxParam = self.getParameterByName("Max");
        var pageIndex = self.getParameterByName("pageID");
        var otherContactId = self.getParameterByName("kontaktid");
        var getNextPage = !!nextPageNumber;
        var getPrevPage = false;
        var isInsidePopup = targetKey.indexOf(".modal") > -1;

        if (nextPageNumber !== undefined && onlyUpdateProductList) {
            pageIndex = nextPageNumber;
        }
        if (getNextPage) {
            pageIndex = nextPageNumber;

            if (onlyUpdateProductList && nextPageNumber < self.CurrentPageNumber) {
                getPrevPage = true;
            }
            self.showPageLoadingIcon();
        }
        if (self.deviceSize == "")
            self.deviceSize = mcWeb.responsive.getScreenSize();
        var isForInstantSearch = targetKey.indexOf(".d4-instant-search-loadbyajax") >= 0;
        var isForLeftSide = targetKey.indexOf(".load-left") >= 0;

        var fieldRequestFilter = {};
        var requestFilterAdded = false;
        var sendFilterOnly = false;
        for (var i = 0; i < fieldsToLoad.length; i++) {
            var current = $(fieldsToLoad[i]);
            var isProductListField = false;
            var areaid = current.data("area-id");

            if (onlyUpdateProductList) {
                if (areaid !== "CenterContentDynamicAdList" && areaid !== "CenterContentDynamicProdListAlternate")
                    continue;//only need to update productlist, lets skip all other fields

                sendFilterOnly = $(current).hasClass("loaded") && !getNextPage && !self.InitialLoadDone;
                if (pageIndex === 0)
                    pageIndex = self.InitialPageNumber;
            }

            if (current.parents(".stop-ajax-rendering").length > 0)
                continue;//this area is not yet ready to be rendered, it is known to be hidden.

            if (areaid === "CenterContentDynamicAdList" || areaid === "CenterContentDynamicProdListAlternate") {
                if (!self.publishMode) {
                    if (self.ListingTypeGrid && areaid === "CenterContentDynamicProdListAlternate") {
                        current.html('');//remove spinner for fields with no content
                        continue;//only one of the areas should be updated, so skip the area that isn't used for the current listing type
                    }
                    else if (!self.ListingTypeGrid && areaid === "CenterContentDynamicAdList") {
                        current.html('');//remove spinner for fields with no content
                        continue;
                    }
                }

                isProductListField = true;
                var filterContainer = $(filterTargetKey);
                if (filterContainer === undefined || filterContainer === null)
                    return; //filter not meant to be visible

                filterContainer = $(filterContainer);
                var filterClientId = "";
                if (filterContainer != undefined &&
                    filterContainer != null &&
                    filterContainer.length > 0 &&
                    filterContainer[0] != null) {
                    filterClientId = filterContainer[0].id;
                } else doNotRenderFilter = true;

                var cacheKey = "";
                if (self.allowUseOfProductCache && pageIndex <= 1) {
                    //if (!onlyUpdateProductList || doNotRenderFilter) //loading the page, data will have been cached when determining the visibility of fields
                    cacheKey = current.attr("data-cachekey");
                }

                fieldRequestFilter = {
                    NodeId: current.data("node-id"),
                    Url: document.location.pathname + document.location.search,
                    Filter: queryFilterParam,
                    MinPrice: queryMinParam,
                    MaxPrice: queryMaxParam,
                    SearchString: self.getParameterByName("q"),
                    ClientId: filterClientId,
                    PageIndex: pageIndex,
                    FilterCacheKey: cacheKey,
                    FilterIsJson: false,
                    OpenAttributes: self.openAttributesList,
                    OtherContactId: otherContactId
                };
                requestFilterAdded = true;
            }
            else if (jQuery(current).data("sf") === 1) {
                fieldRequestFilter = {
                    NodeId: current.data("node-id"),
                    Url: document.location.pathname + document.location.search,
                    Filter: JSON.stringify(self.currentPopupFilter),//todo: keep a filter after # in the url?
                    MinPrice: null,//queryMinParam, get from filter after # in url
                    MaxPrice: null,//queryMaxParam,get from filter after # in url
                    SearchString: '',
                    ClientId: 'secondary-filter',
                    PageIndex: 0,
                    FilterCacheKey: '',
                    FilterIsJson: self.currentPopupFilter !== undefined && self.currentPopupFilter !== null,
                    OpenAttributes: null,
                    OtherContactId: otherContactId
                };
                requestFilterAdded = true;
            }
            else if (!requestFilterAdded) {
                fieldRequestFilter = {
                    NodeId: current.data("node-id"),
                    Url: document.location.pathname + document.location.search,
                    Filter: '',//todo: keep a filter after # in the url?
                    MinPrice: null,//queryMinParam, get from filter after # in url
                    MaxPrice: null,//queryMaxParam,get from filter after # in url
                    SearchString: self.currentSearchKeyword,
                    ClientId: '',
                    PageIndex: 0,
                    FilterCacheKey: '',
                    FilterIsJson: false,
                    OpenAttributes: null,
                    OtherContactId: otherContactId
                };
                if (current.data("filter-id") != null && current.data("filter-id") != undefined && current.data("filter-id").length > 0) {
                    fieldRequestFilter.ClientId = current.data("filter-id");
                }
                requestFilterAdded = true;
            }

            if (!onlyUpdateProductList || isProductListField) {
                var width = self.getFieldWidth(current, isForInstantSearch);
                var myPlids = null;
                var myPlidsString = current.data('plids');
                if (myPlidsString != undefined && myPlidsString != null)
                    myPlids = myPlidsString;

                var myPlid = current.data('plid');
                if (self.PreviewProduktLagerId != null) {
                    myPlid = self.PreviewProduktLagerId;
                }
                if (targetKey != "div.load-later .ajax-field.not-loaded[data-sf='0']" || width > 0) {
                    var fieldData = {
                        Width: width,
                        AreaName: current.data("area-id"),
                        FieldId: current.data("field-id"),
                        NodeId: current.data("node-id"),
                        ClientId: current[0].id,
                        UseSpecificLayoutId: current.data("use-specific-layout"),
                        LayoutId: current.data('layoutid'),
                        ManufacturerId: current.data('manufacturerid'),
                        VariantSorting: current.data('variant-sorting'),
                        Plid: myPlid,
                        PlidList: myPlids,
                        SendFilterOnly: sendFilterOnly,
                        GroupedVariants: current.data('grouped-variants'),
                    };
                    fieldsArray.push(fieldData);
                }
                else {
                    current.find("i.icon-spinner").remove();
                }
            }
        }
        var skipSorter = getPrevPage;
        self.PreviewProduktLagerId = null;
        if (fieldsArray.length > 0) {
            self.service.PostAction("RenderFields", {
                Data: fieldsArray, RequestFilter: fieldRequestFilter,
                SkipFilterRendering: doNotRenderFilter || getNextPage, UseDummyData: useDummyData, DeviceSize: self.deviceSize, GetElementsOnly: getNextPage, SkipSorter: skipSorter,
                CurrentArticle: self.currentArticleId, PopupFilter: popupFilter
            }).then(
                function (result) {
                    if (result != null) {
                        if (isForInstantSearch || self.reloadFromCallbackActive || isInsidePopup || pageIndex > 1)
                            result.AllowDeferred = false;
                        if (getPrevPage) {
                            result.Prepend = true;
                        } else {
                            result.Prepend = false;
                        }

                        if (isForLeftSide)
                            self.updateLeftSideContent(result);
                        else self.updateFieldContent(result);

                        if (result.EmptyFields !== null && result.EmptyFields.length > 0) {
                            for (var i = 0; i < result.EmptyFields.length; i++) {
                                $("#" + result.EmptyFields[i]).html('');//remove spinner for fields with no content
                            }
                        }
                        self.reloadFromCallbackActive = false;
                        if (getNextPage) {
                            pageIndex = nextPageNumber;
                            self.hidePageLoadingIcon();
                        }

                        if (targetKey.indexOf("div.load-later") >= 0) {
                            if (self.InitialLoadDone == false && window.D4DeferredScripts != undefined) {
                                setTimeout(function () {
                                    var parser = new DOMParser;
                                    var dom = parser.parseFromString(
                                        '<!doctype html><body>' + window.D4DeferredScripts,
                                        'text/html');
                                    var decodedString = dom.body.textContent;

                                    if (window.D4SingleScript) {
                                        //we only have the path; let's use jquery to load the script
                                        jQuery.getScript(decodedString,
                                            function (data, textStatus, jqxhr) {
                                                //console.log(data); // Data returned
                                                console.log(textStatus); // Success
                                                console.log(jqxhr.status); // 200
                                                console.log("Load was performed.");
                                            });
                                    } else {
                                        jQuery("body").append(decodedString);
                                    }

                                },
                                    5);
                            }
                            self.setupSubscriptions();
                            setTimeout(self.postStatistics, 250);
                        }
                    }

                    if (self.fieldsToLoad != undefined && self.fieldsToLoad.length > 0) {
                        self.runUpdateFields();
                    } else {

                        if (!self.InitialLoadDone) {
                            self.setupSubscriptions();
                        }
                    }
                    if (isInsidePopup) {
                        PubSub.publish(mcWeb.lightbox.events.contentChanged);
                    }

                }
            );
        }
        else if (!self.InitialLoadDone) {
            if (self.fieldsToLoad !== undefined && self.fieldsToLoad.length > 0) {
                self.runUpdateFields();
            }
            else {
                if (!self.InitialLoadDone) {
                    self.setupSubscriptions();
                }
            }
        }

    };

    self.updateLeftSideContent = function (fieldData) {
        if (!fieldData.Success || fieldData.Data.length == 0)
            return;

        self.leftSideFieldsReadyToLoad = fieldData;

        var myInnerData = fieldData.Data[0];
        myInnerData.targetField = $('#' + myInnerData.ClientId);

        if (McUtils.isElementInView(myInnerData.targetField)) {
            //first field in left side is within the view, let's load now
            self.addFieldContentToPage(fieldData, myInnerData.targetField, myInnerData);
            myInnerData = fieldData.Data.shift();
        }
        if (myInnerData == null)
            return;

        myInnerData.targetField = $('#' + myInnerData.ClientId);
        self.addScrollObserverForLeftSide(document.getElementById(myInnerData.ClientId));

    }

    this.addScrollObserverForLeftSide = function (field) {
        var config = {
            // If the image gets within 50px in the Y axis, start the download.
            rootMargin: '0px 0px',
            threshold: 0.01
        };
        self.leftAreaScrollObserver = new IntersectionObserver(self.onLeftSideIntersection, config);
        self.leftAreaScrollObserver.observe(field);
    };

    self.onLeftSideIntersection = function (entries) {
        var entry = null;
        if (entries.length <= 0)
            return;

        var entry = entries[0];
        if (entry.intersectionRatio <= 0)
            return;
        self.addRemainingLeftSideFieldsToDom();
        self.leftAreaScrollObserver.unobserve(entry.target);
        self.leftAreaScrollObserver = null;
    };

    self.addRemainingLeftSideFieldsToDom = function () {
        for (var i = 0; i < self.leftSideFieldsReadyToLoad.Data.length; i++) {

            var myInnerData = self.leftSideFieldsReadyToLoad.Data[i];
            myInnerData.targetField = $('#' + myInnerData.ClientId);
            self.addFieldContentToPage(self.leftSideFieldsReadyToLoad, myInnerData.targetField, myInnerData);
        }
        self.leftSideFieldsReadyToLoad = null;
    };
    self.getFieldWidth = function (current, isForInstantSearch) {
        var width = current.width();
        if (width == 0 && isForInstantSearch) {
            if (self.deviceSize == "sm")
                width = 200;
        }
        else if (width == 0) {
            var fieldParents = jQuery(current).parents(".web-pub-field.container, .web-pub-field.container-fluid");
            for (var parentIndex = 0; parentIndex < fieldParents.length; parentIndex++) {
                if (fieldParents[parentIndex] != null) {
                    var currentParent = jQuery(fieldParents[parentIndex]);
                    var tmpWidth = currentParent.width();
                    if (tmpWidth > 0) {
                        width = tmpWidth;
                        break;
                    }
                }
            }
        }
        if (width <= 0) {
            var $current = $(current);
            //if this is a field that will open in a popup, and the popup is not visible yet, the width must be set by the size of the lightbox
            if ($current.data("popup") > 0) {
                var lightboxLocator = "#center-popup";
                if ($current.data("popup-plc") == 1) {
                    lightboxLocator = "#rhs-popup";
                }
                width = $(lightboxLocator).width();
            }
        }

        return width;
    };

    self.setupSubscriptions = function () {
        if (self.subscriptionsInitialised)
            return; //we've done this already
        self.subscriptionsInitialised = true;

        self.disableDoubleClick("ad-buy-button");
        PubSub.subscribe(AjaxRenderingEngineEventTypes.onFieldContentChanged,
            function (data, message) {
                self.disableDoubleClick("ad-buy-button");
                if (self.publishMode) {
                    mcWeb.publisher.checkElementsMarkedInSessionStorage();
                }
            });
       
        
        PubSub.subscribe(AjaxRenderingEngineEventTypes.onPriceRefreshRequested,
            function (message, data) {
                if (data !== undefined && data !== null) {
                    self.updateContentForPreloadedFields(data.Target,false);
                }
                else self.updateContentForPreloadedFields("div.ajax-field", false);
            });

        PubSub.subscribe(mcWeb.cartsmall.events.onShowNewItemInCartText,
            function (message, data) {
                if (data.favouriteCart != undefined && data.favouriteCart)
                    return; //we don't show this popup when products were added favourite carts

                var lightboxModal = jQuery("#center-popup").data('bs.modal');
                if (lightboxModal !== null && lightboxModal !== undefined && lightboxModal.isShown)
                    return;
                var loadPopupParams2 = {
                    targetKey: "div.ajax-field.not-loaded[data-popup=6]",
                    filterTargetKey: "#AttributeListBox",
                    onlyUpdateProductList: false,
                    doNotRenderFilter: true,
                    useDummyData: false,
                    popupFilter: 6,
                    nextPageNumber: 0
                };
                self.fieldsToLoad.push(loadPopupParams2);
                self.runUpdateFields();

                var loadPopupParams = {
                    targetKey: "div.ajax-field.not-loaded[data-popup=2]",
                    filterTargetKey: "#AttributeListBox",
                    onlyUpdateProductList: false,
                    doNotRenderFilter: true,
                    useDummyData: false,
                    popupFilter: 2,
                    nextPageNumber: 0
                };
                self.fieldsToLoad.push(loadPopupParams);
                self.runUpdateFields();

            });

        PubSub.subscribe(mcWeb.cart.events.onPopupForProductAssembly,
            function (message, data) {
                var p = {
                    targetKey: "div.ajax-field.not-loaded[data-popup=8]",
                    filterTargetKey: "#AttributeListBox",
                    onlyUpdateProductList: false,
                    doNotRenderFilter: true,
                    useDummyData: false,
                    popupFilter: 8,
                    nextPageNumber: 0
                };
                self.updateFields(p.targetKey, p.filterTargetKey, p.onlyUpdateProductList, p.doNotRenderFilter, p.useDummyData, p.popupFilter, p.nextPageNumber);
            });

        PubSub.subscribe(AjaxRenderingEngineEventTypes.onPopupFilterChanged,
            function (message, data) {
                // data contains attribute, attributevalue and the sender
                //use this to find the nearest field that should be filtered by the attribute
                self.currentPopupFilter = data.FullFilter;

                var nextF = jQuery(data.Sender).parent().parent().next();
                if (nextF.length > 0) {

                    var nextField = jQuery(nextF).find('.ajax-field');
                    if (nextField.length > 0) {
                        //we should now have stored the current filter in self.currentPopupFilter, so now we can ask
                        //for the field content to be updated
                        var targetFieldId = nextField[0].id;
                        var targetFieldPopupRule = jQuery(nextField).data("popup");

                        var loadPopupParams = {
                            targetKey: "#" + targetFieldId,
                            filterTargetKey: "AttributeListBox",
                            onlyUpdateProductList: false,
                            doNotRenderFilter: false,
                            useDummyData: false,
                            popupFilter: targetFieldPopupRule,
                            nextPageNumber: 0
                        };
                        self.fieldsToLoad.push(loadPopupParams);
                        self.runUpdateFields();
                    }
                }

            });

        PubSub.subscribe(AjaxRenderingEngineEventTypes.onPushProductsIntoField,
            function (message, data) {
                var fieldNode = jQuery(".ajax-field[data-field-id=" + data.FieldId + "]");
                if (fieldNode.length === 0) {
                    console.log("node not found");
                    return;
                }
                if (data.Plids !== undefined && data.Plids !== null && data.Plids.length > 0) {
                    jQuery(fieldNode[0]).data("plids", data.Plids);
                    jQuery(fieldNode[0]).data("filter-id", "secondary-filter");

                    var param = {
                        targetKey: "#" + jQuery(fieldNode[0]).attr("id"),
                        filterTargetKey: "secondary-filter",
                        onlyUpdateProductList: false,
                        doNotRenderFilter: false,
                        useDummyData: false,
                        popupFilter: null,
                        nextPageNumber: 0
                    };

                    if (data.ExtraData !== undefined && data.ExtraData != null) {
                        self.placeholderDict["extraData" + data.FieldId] = data.ExtraData;                        
                    }

                    
                    self.updateFields(param.targetKey,
                        param.filterTargetKey,
                        param.onlyUpdateProductList,
                        param.doNotRenderFilter,
                        param.useDummyData,
                        param.popupFilter,
                        param.nextPageNumber);
                }
                else {
                    jQuery(fieldNode[0]).attr("data-Plids", data.Plids);
                    jQuery(fieldNode[0]).attr("data-filter-id", "secondary-filter");
                    jQuery(fieldNode).html('');
                    jQuery(".secondary-filter").html('');
                }

            });

        mcWeb.ajaxPopup.load();//sets up subscriptions for event-controlled pop-ups
        self.checkForPopupAfterProductInfoVisit();
        self.checkForPopupFirstPageView();
        self.checkForPopupAfterLogin();
        (function () {

            if (typeof window.CustomEvent === "function") return false;

            function CustomEvent(event, params) {
                params = params || { bubbles: false, cancelable: false, detail: null };
                var evt = document.createEvent('CustomEvent');
                evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
                return evt;
            }

            window.CustomEvent = CustomEvent;
        })();

        const formHead = document.querySelector('head');
        formHead.dispatchEvent(new CustomEvent("readyForGtm", { bubbles: true, cancelable: false, detail: null }));
        self.InitialLoadDone = true;
        //todo: her trenger vi starte å prosessere en ny kø med jobber med javascript kall som legges inn av HTMLen som kommer publisert i f.eks. artikler.

    };

    self.checkForPopupFirstPageView = function () {
        if (!!self.firstPageViewPopup) {
            if (jQuery("body.publish-mode").length <= 0) {

                var param = self.firstPageViewPopup;
                self.firstPageViewPopup = null;
                self.updateFields(param.targetKey,
                    param.filterTargetKey,
                    param.onlyUpdateProductList,
                    param.doNotRenderFilter,
                    param.useDummyData,
                    param.popupFilter,
                    param.nextPageNumber);
            }
            else {
                self.firstPageViewPopup = null;
            }

        }

    }
    self.disableDoubleClick = function (classToDisable) {
        jQuery('.' + classToDisable).on("dblclick", function (e) {
            e.preventDefault();
        });
    };
    self.checkForPopupAfterLogin = function () {
        const readyForPostLoginPopup = localStorage.getItem('ReadyForPostLoginPopup') === 'true';
        if (!!readyForPostLoginPopup) {
            if (jQuery("body.publish-mode").length <= 0) {

                var loadPopupParams = {
                    targetKey: "div.ajax-field.not-loaded[data-popup='9']",
                    filterTargetKey: "#AttributeListBox",
                    onlyUpdateProductList: false,
                    doNotRenderFilter: true,
                    useDummyData: false,
                    popupFilter: 9,
                    nextPageNumber: 0
                };

                self.updateFields(loadPopupParams.targetKey,
                    loadPopupParams.filterTargetKey,
                    loadPopupParams.onlyUpdateProductList,
                    loadPopupParams.doNotRenderFilter,
                    loadPopupParams.useDummyData,
                    loadPopupParams.popupFilter,
                    loadPopupParams.nextPageNumber);
            }
            window.StorageService.localStorage.setItem('ReadyForPostLoginPopup', 'false');
        }

    }
    self.checkForPopupAfterProductInfoVisit = function () {
        var param = {
            NodeId: jQuery("#hidden-nodeid").text()
        };
        self.statsService.PostAction("CheckForPopupAfterProductVisit", param).then(function (result) {
            if (self.fieldsToLoad == undefined)
                self.fieldsToLoad = [];
            if (result) {
                var loadPopupParams = {
                    targetKey: "div.ajax-field.not-loaded[data-popup='4']",
                    filterTargetKey: "#AttributeListBox",
                    onlyUpdateProductList: false,
                    doNotRenderFilter: true,
                    useDummyData: false,
                    popupFilter: 4,
                    nextPageNumber: 0
                };

                self.updateFields(loadPopupParams.targetKey,
                    loadPopupParams.filterTargetKey,
                    loadPopupParams.onlyUpdateProductList,
                    loadPopupParams.doNotRenderFilter,
                    loadPopupParams.useDummyData,
                    loadPopupParams.popupFilter,
                    loadPopupParams.nextPageNumber);

            } else {
                var loadPopupParams = {
                    targetKey: "div.ajax-field.not-loaded[data-popup='5']",
                    filterTargetKey: "#AttributeListBox",
                    onlyUpdateProductList: false,
                    doNotRenderFilter: true,
                    useDummyData: false,
                    popupFilter: 5,
                    nextPageNumber: 0
                };

                self.updateFields(loadPopupParams.targetKey,
                    loadPopupParams.filterTargetKey,
                    loadPopupParams.onlyUpdateProductList,
                    loadPopupParams.doNotRenderFilter,
                    loadPopupParams.useDummyData,
                    loadPopupParams.popupFilter,
                    loadPopupParams.nextPageNumber);
            }
        });
    };

    self.addFieldSorterToPage = function (data, $targetField, fieldData) {
        if (data.FieldSorterResponse !== null &&
            data.FieldSorterResponse.length > 0 && !fieldData.Prepend) {//when we prepend the page content, we don't need to update the "go to next page" link
            var myFieldContainer = $($targetField.parent()).parent();
            // fjern eksisterende paging før vi legger til den nye
            var oldFieldSorter = $(myFieldContainer).find(".FieldSorter");
            oldFieldSorter.remove();
            if (fieldData.PagingMode <= 1 || $("#mmenu").length > 0 || $("body.mc-filter-left").length > 0) {
                $(myFieldContainer).prepend(data.FieldSorterResponse);
            }

            if (data.FieldSorter2Response !== null &&
                data.FieldSorter2Response.length > 0)
                $(myFieldContainer).append(data.FieldSorter2Response);

            var btn = $('#filter-btn');
            btn.show();

            PubSub.publish(mcWeb.MegaMenuResponsive.events.onAddNewFilterButton, null);
        }
    };

    self.addFieldContentToPage = function (fieldData, targetField, data) {
        self.bottomImageToLoad = null;
        if (targetField != undefined && !data.added) {
            var $targetField = $(targetField);

            data.added = true;
            self.addFieldSorterToPage(data, $targetField, fieldData);
            if (fieldData.FilterCacheKey !== null)//todo: check how to make this null when page index is not 1
                $targetField.attr("data-cachekey", fieldData.FilterCacheKey);//update cache key. Especially useful when filtering is being applied, to allow quick reload of previously used filters

            self.registerStatistics(data);

            if (data.LoadFilter) {
                this.updateFilterContent(data);

            }
            if (!fieldData.AddToExistingField) {
                if (!data.SendFilterOnly) {//we called this only to get the attribute filter when SendFilterOnly is true
                    $targetField.html(data.Response);
                    self.refineInsertedContent(targetField, data);                    
                }

                var imageList = $targetField.find(".AddProductImage img");
                var lastImage = imageList.last();
                if (lastImage != null && lastImage.length > 0) {
                    if (self.containsLoadableImage(lastImage))
                        self.bottomImageToLoad = lastImage;
                }
                else if (imageList.length <= 0) {
                    //No images found by looking for them in ads, so check for articles...                               
                    if (self.bottomImageToLoad === null || self.bottomImageToLoad === undefined) {
                        //we might only have articles in the field, let's look for one...
                        var backgroundImgItems = $targetField.find("div.ArticleWithBackground.D4Standard .inner-content");
                        if (backgroundImgItems.length > 0) {
                            var bgItem = backgroundImgItems.last();
                            if (bgItem != null) {
                                if (self.containsLoadableImage(bgItem))
                                    self.bottomImageToLoad = bgItem;
                            }
                        }
                    }
                }

                PubSub.publish(AjaxRenderingEngineEventTypes.onFieldContentChanged, data);
            } else {
                if (fieldData.Prepend) {
                    $targetField.prepend(data.Response);
                    PubSub.publish(AjaxRenderingEngineEventTypes.onFieldContentChanged, data);
                } else {
                    $targetField.append(data.Response);
                    PubSub.publish(AjaxRenderingEngineEventTypes.onFieldContentChanged, data);
                }
                self.refineInsertedContent(targetField, data);
            }
            self.initialiseAdLinks();
            if (fieldData.InitializeRoyalSlider)
                InitializeRoyalSliderInAds("#" + data.ClientId + " .variant-slider");



            self.searchPageArea(data);
            if (!data.SendFilterOnly && (data.Response === null || data.Response.length === 0)) {
                if (data.AreaName === "CenterContentDynamicAdList") {
                    //find and hide the Sorter elements for ad-listing
                    $(".FieldSorter.web-pubfield-sort.CenterContentDynamicAdList").hide();
                } else if (data.AreaName === "CenterContentDynamicProdListAlternate") {
                    //find and hide the Sorter elements for ad-listing
                    $(".FieldSorter.web-pubfield-sort.CenterContentDynamicProdListAlternate").hide();
                }
            }
            else {
                if (data.AreaName === "CenterContentDynamicAdList") {
                    //find and hide the Sorter elements for ad-listing
                    $(".FieldSorter.web-pubfield-sort.CenterContentDynamicAdList").show();
                } else if (data.AreaName === "CenterContentDynamicProdListAlternate") {
                    //find and hide the Sorter elements for ad-listing
                    $(".FieldSorter.web-pubfield-sort.CenterContentDynamicProdListAlternate").show();
                }
            }
            if (fieldData.IsDummyField) {
                $targetField.click(self.selectLayoutClick);
                $targetField.addClass("wired" + data.ClientId);
            }

            if (data.LayoutCssClass != null && data.LayoutCssClass != undefined) {
                var fieldToUpdateLayout = $targetField.closest('.web-pub-field:not(.' + data.LayoutCssClass + ")");
                $(fieldToUpdateLayout).removeClass(function (index, className) {
                    return (className.match(/(^|\s)layout-\S+/g) || []).join(' ');
                });
                $(fieldToUpdateLayout).addClass(data.LayoutCssClass);
            }
        }
    };

    // Define the IsNoHitSearchPage function
    this.searchPageArea = function (data) {
        if (data.AreaName !== "CenterContentDynamicAdList" && data.AreaName !== "CenterContentDynamicProdListAlternate")
            return;

        if (data.IsNoHitSearch) {
            jQuery(".search-no-result").show();
            self.updateContentForPreloadedFields(".search-no-result div.ajax-field.not-loaded", false);
            if (data.ShowInStockOnly || data.ShowInRemoteStockOnly) {
                jQuery(".search-no-result-not-instock").show();
                // Hide the lhs filterbutton
            } else {
                jQuery(".search-no-result-not-instock").hide();
            }
        }
        else if (data.IsProductListing) {
            jQuery("body:not(.publish-mode) .search-no-result").hide();
        }
    };

    this.containsLoadableImage = function (currImg) {
        if (currImg.length !== undefined && currImg.length > 0) {
            for (var i = 0; i < currImg.length; i++) {
                if (self.containsLoadableImage(currImg[i]))
                    return true;
            }
            return false;
        }

        if (currImg != null && currImg.src != undefined && currImg.src != null)
            return true;
        else if ($(currImg).hasClass("inner-content")) {
            var bg = $(currImg).css('background-image').trim();
            if (bg.length > 0 && bg !== "none") {
                return true;
            }
        }

        return false;
    };

    this.initializeSlideShow = function ($targetField, slideshowmodel) {
        if (slideshowmodel == null || slideshowmodel == undefined)
            return;

        var myParent = $targetField.closest('.web-pub-field');
        if (!$(myParent).hasClass('NoSlide'))
            return;

        $(myParent).addClass('around_slides');
        $(myParent).removeClass('NoSlide');
        $targetField.addClass('royalSlider');

        mcSlide.InitSlideshow(myParent[0].id,
            slideshowmodel.Play,
            slideshowmodel.Pause,
            slideshowmodel.HoverPause,
            slideshowmodel.GenerateNextPrev,
            slideshowmodel.GeneratePagination,
            slideshowmodel.ArrowsNavAutoHide,
            slideshowmodel.FadeSpeed,
            slideshowmodel.SlideSpeed,
            slideshowmodel.Effect,
            slideshowmodel.CrossFade,
            slideshowmodel.Orientation,
            slideshowmodel.ScaleType,
            slideshowmodel.Randomize,
            slideshowmodel.Css,
            slideshowmodel.IsMobileDomain,
            slideshowmodel.Width,
            slideshowmodel.Height,
            true);
    };

    this.resolveImagesOnImageLoaded = function (image) {
        return new Promise((resolve, reject) => {
            if (image.complete) {
                resolve();
            } else {
                image.onload = () => {
                    resolve();
                };
            }
        });
    };

    this.getArrayOfArrays = function (inputArray, itemsPerArray) {
        var arrayOfArrays = [];
        for (var i = 0; i < inputArray.length; i += itemsPerArray) {
            arrayOfArrays.push(inputArray.slice(i, i + itemsPerArray));
        }
        return arrayOfArrays;
    };

    this.getSlideshowModel = function($targetField, data){
        if (data != null && data.SlideShowModel != undefined && data.SlideShowModel != null) 
            return  data.SlideShowModel;
        
        if (!$targetField.hasClass("slides_container"))
            return null;

        //may store slideshow in the container field
        var parentWithSlideInfo = $targetField.parents(".web-pub-field[data-slideshow]");
        if (parentWithSlideInfo.length <= 0)
            return null;

        return parentWithSlideInfo.data("slideshow");
    };

    this.ensureCorrectImageSizes = function (images, $targetField, data, lazyImg, initialiseSlideshows) {
        
        //Note! Array.from for IE has been added to the McWeb4-standard.js file.
        //when images are either not in need of resizing, or the new images have been fetched, we try to set up any slideshow for the field.

        if ((images === null || images === undefined || images.length <= 0) && initialiseSlideshows) {
            //no need to load images, so let's just see if slideshow must be applied
            var slidemodel = self.getSlideshowModel($targetField, data);
            if (slidemodel !== null) {
                self.initializeSlideShow($targetField, slidemodel);
            }
            
            return;
        }
        var nodeId = jQuery('#hidden-nodeid').text();
        var itemsPerArray = 8;
        if (data != null && (data.ImgModuleVer >= 5 || data.SlideShowModel != null && data.SlideShowModel != undefined ))
            itemsPerArray = images.length; // must keep it down to 1 request for images when dealing with slideshow, or when ImageModulVersion >= 5

     
        var imageLoadedPromises = Array.from(images).map(self.resolveImagesOnImageLoaded);
        Promise.all(imageLoadedPromises)
            .then(() => {
                
                var imageList = images.toArray().map(i => self.getParamForImage(i, lazyImg, nodeId)).filter(y => y !== null);

                var arrayOfImageArrays = self.getArrayOfArrays(imageList, itemsPerArray);

                var requestForImagesSent = false;
                for (var arrayId = 0; arrayId < arrayOfImageArrays.length; arrayId++) {
                    var items = arrayOfImageArrays[arrayId];
                    requestForImagesSent = true;
                    self.imageService.PostAction("ListImageTagsAsync", { Items: items }).then(function (result) {
                        var delaySlideshow = false;
                        if (result && result.items && result.items.length > 0) {
                            var items = result.items;
                            for (var i = 0; i < items.length; i++) {
                                var item = items[i];
                                if (!item.success)
                                    continue;
                                delaySlideshow = true;//slideshow must wait for these new images have loaded

                                if (item.tag !== "")
                                    jQuery('#' + item.targetId).replaceWith(item.tag);
                                else {
                                    var image = jQuery('#' + item.targetId + "  img.d4-lazy-thumb");
                                    image.removeClass("d4-lazy-thumb");
                                    image.addClass("d4-prod-thumb");
                                }
                            }
                        }
                        if (!initialiseSlideshows)
                            return;

                        var slidemodel = self.getSlideshowModel($targetField, data);

                        if (slidemodel === null)
                            return;

                        if (delaySlideshow) {//need to await the new images have been loaded before we set up slideshow
                            var newImages = $targetField.find("img.d4-prod-thumb");
                            self.initializeSlideShowAfterImagesLoaded($targetField, newImages, slidemodel);
                        }
                        else self.initializeSlideShow($targetField, slidemodel);
                    });
                }
                if (!initialiseSlideshows)
                    return;

                var slidemodel = self.getSlideshowModel($targetField, data);
                if (!requestForImagesSent && slidemodel !== null) {
                    self.initializeSlideShow($targetField, slidemodel);
                }
            });
       
    };

    this.getParamForImage = function (image, lazyImg, nodeId) {
        var img = jQuery(image);
        var parent = img.parent();
        if (parent === null || parent === undefined || parent.length <= 0)
            return null;

        var containerTagType = parent[0].tagName.toLowerCase();
        var includeLink = containerTagType === "a";
        var parentWidth = parent.width();
        var parentHeight = parent.height();
        var myWidth = img.data("width");
        var myHeight = img.data("height");
        var usesAltImg = img.data("altImg") === 1;
        var imgStatus = img.data("status");
        var elementId = img.data("elementid");
        var css = img.data("css");
        var autoscale = img.data("autoscale");        

        if (usesAltImg || (!lazyImg && (imgStatus == undefined || imgStatus.toUpperCase() !== "OK")))
            return null;

        if (!lazyImg  && myWidth / parentWidth >= 0.90)
            return null;//if lazyImg, no image loaded so we must allways load a new image

        var aspect = 1;
        if (parentWidth > 0 && parentHeight > 0)
            aspect = parentWidth / parentHeight;
        else if (myWidth > 0 && myHeight > 0)
            aspect = myWidth / myHeight;//applies when parent not visible and hence no parent size available

        if (aspect < 0.1 || aspect > 2) {
            //lazy loading in use, must use different aspect calculation
            aspect = myWidth / myHeight;
        }
        var myId = img[0].id;
        if (myId === "")
            myId = parent[0].id;

        var item = {
            TargetId: myId,
            Plid: img.data("plid"),
            Index: img.data("imageIndex"),
            Width: parentWidth,
            Aspect: aspect,
            IncludeLink: includeLink,
            UseSecondaryImage: img.data("hassecondaryimg") === 1,
            IncludeNotFound: lazyImg,
            ElementId: 0,
            Css: '',
            AutoScale: autoscale,
            NodeId: nodeId
        };
        if (elementId !== undefined && elementId !== null)
            item.ElementId = elementId;
        if (css !== undefined && css !== null)
            item.Css = css;

        return item;
    };

    this.initializeSlideShowAfterImagesLoaded = function($targetField, images, slidemodel){
        if(images !== null && images !== undefined && images.length > 0){
            var imageLoadedPromises = Array.from(images).map(self.resolveImagesOnImageLoaded);
            Promise.all(imageLoadedPromises)    
            .then( () => {
                if (slidemodel != undefined && slidemodel != null){
                    self.initializeSlideShow($targetField, slidemodel);
                    }
                });
            }
        };

    this.initialiseAdLinks = function () {
        setTimeout(function () {
            if (window.D4LinkArraySetup != undefined && window.D4LinkArraySetup != null) {
                for (var i = 0; i < window.D4LinkArraySetup.length; i++) {
                    var current = window.D4LinkArraySetup[i];
                    makeAdLinkToProduct(current.elementId, current.productLink);
                }
                window.D4LinkArraySetup = []; //clearing it
                //linker virker ikke enda etter at nye elementer er lagt til
                //må debugge dette!

            }
        },
            5);
    };

    self.stats = [];

    this.registerStatistics = function (data) {
        if (data != undefined && data != null && !self.InitialLoadDone && (data.PopupRule == undefined || data.PopupRule <= 0)) { // && (data.SlideShowModel == undefined || data.SlideShowModel == null)) {
            if (self.CheckStatistics) {
                self.stats.push(data);
            }
        }
    };

    this.postStatistics = function () {
        try {
            if (self.stats != null && self.stats.length > 0) {
                var data = self.stats[0];
                var stats = {
                    DeviceSize: self.deviceSize,
                    NodeId: data.NodeId,
                    FieldIds: [],
                    Note: 'FieldsShownBeforeScroll'
                };
                for (var i = 0; i < self.stats.length; i++) {
                    stats.FieldIds.push(self.stats[i].FieldId);
                }
                self.service.PostAction("PostStatistics", stats).then(function () {
                    self.stats = [];
                });
            }
        }
        catch (e) { }
    };

    this.updateContentForSpecificFields = function (fieldRecognizer) {
        self.addRemainingFieldsToDom();
        self.reloadFromCallbackActive = true;
        self.updateFields(fieldRecognizer, "#AttributeListBox", false, true, false, 0, 0);
    };

    this.updateContentForPreloadedFields = function(fieldRecognizer, includeMegaMenuChildren){
        var recognizer = "div.ajax-field.loaded";
        if(fieldRecognizer !== undefined){
            recognizer = fieldRecognizer;
        }
        var preloadedFields = jQuery(recognizer);

        var fieldItems = [];
        for(var j = 0; j< preloadedFields.length; j++){
            var field = jQuery( preloadedFields[j]);
            var fieldItem = {
                FieldId: field.data('field-id'),
                NodeId: field.data('node-id'),
                UniqueId: field[0].id
            };
            if (!includeMegaMenuChildren  && $(field).parents(".dropdown-submenu").length > 0)
                continue;//fields within mega menu are not to be loaded here, will be done when menu is shown.

            var preloadedElements = field.find("div.WebPubElement, div.separator-element[data-separator-level=100]");
            var eItems = [];
            for(var i = 0; i < preloadedElements.length; i++){
                var element =jQuery( preloadedElements[i]);
                var eItem =  {
                    ElementId: element.data('elementid'),
                    Plid: element.data('plid')
                };
                eItems.push(eItem);
            }
            fieldItem.Elements = eItems;
            fieldItems.push(fieldItem);
        }

        startParaxify('.d4-article-parallax');

        if(fieldItems.length > 0){
            self.service.PostAction("GetPriceDataAsync", { Fields: fieldItems }).then( function (result) {
                if(result && result.Success){
                    var fields = result.Fields;

                    for(var i = 0; i < fields.length; i++){
                        var fData = fields[i];
                        var field = jQuery("#" + fData.UniqueId);
                        self.refineInsertedContent(field, fData);
                    }
                }
            });
        }
        
        self.showSearchSynonymText();

    }
    this.showSearchSynonymText = function () {
        if (jQuery(".search-synonyms").length > 0)
            return;

        var alteredSearchResultArea = jQuery(".web-pub-field[data-search-synonyms]");
        if (alteredSearchResultArea.length <= 0)
            return;

        var alteredText = alteredSearchResultArea.data("search-synonyms");
        if (alteredText !== undefined && alteredText !== null && alteredText.length <= 0)
            return;
        var newContent = "<H3 class='search-synonyms'>" + alteredText + "</H3>";
        alteredSearchResultArea.first().before(newContent);
        
    };
    this.applyModelRegisterExtraData = function (targetfield, fieldId) {
        if (targetfield === null || targetfield === undefined || fieldId === undefined || fieldId === null)
            return;
        if (self.placeholderDict === null || self.placeholderDict === undefined)
            return;
        var extraData = self.placeholderDict["extraData" + fieldId]
        if (extraData === undefined || extraData === null)
            return;

        for (var i = 0; i < extraData.length; i++) {
            var item = extraData[i];
            if (item === null || item.Plid <= 0)
                continue;

            if (item.AccessoriesGroup !== null) {
                let accessoriesgroup = $(".mod-reg-ag.mode-reg-id-" + item.Plid);
                accessoriesgroup.html(item.AccessoriesGroup);
                accessoriesgroup.removeClass("hidden");
            }
            if (item.FreeText !== null) {
                let freeTextItem = $(".mod-reg-ft.mode-reg-id-" + item.Plid);
                freeTextItem.html(item.FreeText);
                freeTextItem.removeClass("hidden");
            }
        }

    };

    this.applyCustomerSpecifics = function (targetfield, data) {
        if (data === null)
            return;

        self.applyModelRegisterExtraData(targetfield, data.FieldId);

        if (data.PriceData === null || data.PriceData.length <= 0)
            return;
        var $targetField = jQuery(targetfield);

        var priceTags = jQuery(targetfield).find(".locate-prices-" + data.FieldId);

        for (var i = 0; i < data.PriceData.length; i++) {
            jQuery(priceTags).filter("#adprice__" + data.FieldId + "_" + data.PriceData[i].ElementId).each(function (index, item) {
                var $item = jQuery(item);
                $item.html(data.PriceData[i].Price);
                if (data.PriceData[i].Discount !== null && data.PriceData[i].Discount.length > 0) {
                    $item.addClass("has-discount");
                }
                if(data.PriceData[i].NoBuy){
                    var myElement = jQuery(jQuery(item).parents("div.WebPubElement")[0]);
                    myElement.find("a.ad-buy-button").addClass("disabled");
                    myElement.find("a.list-buy-button").addClass("disabled");
                }
                else if(data.PriceData[i].UnlockBuy){
                    var myElement = jQuery(jQuery(item).parents("div.WebPubElement")[0]);
                    myElement.find("a.ad-buy-button").removeClass("disabled");
                    myElement.find("a.list-buy-button").removeClass("disabled");
                }
            });

            if(data.PriceData[i].BidName !== null && data.PriceData[i].BidName.length > 0){
                var countDown = jQuery(".WebPubElement[data-elementid="+data.PriceData[i].ElementId + "] .sale-countdown");
                countDown.data('bid-expires',data.PriceData[i].BidExpires);
            }
//todo: skriv om slik at vi her først legger expire-tiden inn som et data-element på elemented, og så start en timer som går igjennom alle elementene en etter en. Vi ønsker ikke
//å ha mer enn 1 timer pr felt i hvertfall


            if ($targetField.has(".WebPubElement .sale-countdown").length > 0) {
                getLibrary("renderEngineExtras").then(() => { startSaleCountDown($targetField);  })
            }
        }


        var discountTags = $targetField.find(".locate-yousave-" + data.FieldId);

        for (var i = 0; i < data.PriceData.length; i++) {
            var line = data.PriceData[i];
            if (line != null && line.Discount != null && line.Discount.length > 0) {
                jQuery(discountTags).filter("#adyousave__" + data.FieldId + "_" + line.ElementId).each(function (index, item) {
                    var jItem = jQuery(item);
                    jItem.html(line.Discount);
                    jItem.addClass("show");
                });
            }
        }

        var oldPriceTags = $targetField.find(".locate-oldprices-" + data.FieldId);

        for (var i = 0; i < data.PriceData.length; i++) {
            var line = data.PriceData[i];
            if (line.OldPrice != null && line.OldPrice.length > 0) {
                jQuery(oldPriceTags).filter("#adoldprice__" + data.FieldId + "_" + line.ElementId).each(function (index, item) {
                    var jItem = jQuery(item);
                    jItem.html(line.OldPrice);
                    jItem.addClass("show");
                    jItem.prev().addClass("show");//must show the prefix also
                });
            }
        }

        var rrpPriceTags = $targetField.find(".locate-rrpprices-" + data.FieldId);

        for (var i = 0; i < data.PriceData.length; i++) {
            var line = data.PriceData[i];
            if (line.Rrp != null && line.Rrp.length > 0) {
                jQuery(rrpPriceTags).filter("#adrrpprice__" + data.FieldId + "_" + line.ElementId).each(function (index, item) {
                    var jItem = jQuery(item);
                    jItem.html(line.Rrp);
                    jItem.addClass("show");
                });
            }
        }

        var spfTags = $targetField.find(".locate-spf-" + data.FieldId);

        for (var i = 0; i < data.PriceData.length; i++) {
            var line = data.PriceData[i];
            if (line.SpecialFee != null && line.SpecialFee.length > 0) {
                jQuery(spfTags).filter("#adspf__" + data.FieldId + "_" + line.ElementId).each(function (index, item) {
                    var jItem = jQuery(item);
                    jItem.html(line.SpecialFee);
                    jItem.addClass("show");
                });
            }
        }


        var nordeaprices = $targetField.find(".locate-nordeaprices-" + data.FieldId);

        for (var i = 0; i < data.PriceData.length; i++) {
            var line = data.PriceData[i];
            if (line.FinancingTxt != null && line.FinancingTxt.length > 0) {
                jQuery(nordeaprices).filter("#adnordeaprice__" + data.FieldId + "_" + line.ElementId).each(function (index, item) {
                    var jItem = jQuery(item);
                    jItem.html(line.FinancingTxt);
                    jItem.addClass("show");
                });
            }
        }

        var backorders = $targetField.find(".locate-adbackorder-" + data.FieldId);

        for (var i = 0; i < data.PriceData.length; i++) {
            var line = data.PriceData[i];
            if (line.BackorderTxt != null && line.BackorderTxt.length > 0) {
                jQuery(backorders).filter("#adbackorder__" + data.FieldId + "_" + line.ElementId).each(function (index, item) {
                    var jItem = jQuery(item);
                    jItem.html(line.BackorderTxt);
                    jItem.addClass("show");
                });
            }
        }
        
    };

    this.ensureCorrectImageSizesForField = function (targetfield, data) {
        var $field = jQuery(targetfield);
        var images = $field.find("img.d4-prod-thumb.lazy-allowed");
        var lazyImages = $field.find("img.d4-lazy-thumb");

        var loadSlideshowForProdThumb = images.length > 0 && lazyImages.length <= 0; //only start slideshow after loading images for d4-prod-thumb if we don't have d4-lazy-thumbs also
        var loadSlideshowForLazyThumb = lazyImages.length > 0 || !loadSlideshowForProdThumb; //start slideshow if we didn't start it for d4-prod-thumb or we have d4-lazy-thumb

        self.ensureCorrectImageSizes(images, $field, data, false, loadSlideshowForProdThumb);
        self.ensureCorrectImageSizes(lazyImages, $field, data, true, loadSlideshowForLazyThumb);
        EnableLinksForArticlesWithBackground();
        InitializeAnimationsInArticleWithBackground();
    };

    this.refineInsertedContent = function (targetfield, data) {
        self.ensureCorrectImageSizesForField(targetfield, data);
        self.applyCustomerSpecifics(targetfield, data);
        if (typeof lipscore !== 'undefined') {
            lipscore.initWidgets();
        }
        self.initModulesInField(targetfield);
    };

    self.initModulesInField = function (field) {
        jQuery(field).find("script[data-hasRun='false']").each(function (index, item) {
            item.setAttribute("data-hasRun", 'true');
            var theNameSpace = eval(item.getAttribute("data-nameSpace"));
            loadModule(theNameSpace, item.getAttribute("data-moduleInstanceId"), item.getAttribute("data-uniqueId"), null, null);
        });
    };

    self.checkContentHash = function (fieldId, content) {
        let contentHash = self.getHash(content);
        if (StorageService.localStorage.getItem("PopFieldId"+ fieldId) == contentHash)
            return true;
        else 
            StorageService.localStorage.setItem("PopFieldId" + fieldId, contentHash)
        return false;
    }

    self.contentShownBefore = function (fieldData) {
        var isShown = true;
        for (var i = 0; i < fieldData.Data.length; i++) {
            if (!self.checkContentHash(fieldData.Data[i].FieldId, fieldData.Data[i].Response))
                isShown = false;
        }
        return isShown;
    };
    self.getHash = function (str) {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
            var character = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + character;
            hash = hash & hash; // Convert to 32bit integer
        }
        return hash;
    };

    self.preparePopup = function (fieldData, lightboxContainer) {
        if (fieldData.PopupRule == 1)
            if (self.contentShownBefore(fieldData))
                return;

        var isSlideOver = fieldData.PopupPch === "#slideover-placeholder";

        if (isSlideOver && !self.slideOverBoxIsOpen)
            mcWeb.lightbox.movePopupFieldsToOriginalPlaceholder(lightboxContainer);
        else if (!isSlideOver && !self.lightBoxIsOpen)
            mcWeb.lightbox.movePopupFieldsToOriginalPlaceholder(lightboxContainer);

        lightboxContainer.empty();
        var dataFound = false;

        for (var i = 0; i < fieldData.Data.length; i++) {
            var data = fieldData.Data[i];
            if (data.Response.length > 0) {
                dataFound = true;
                break;
            }
        }

        if (dataFound) {
            var popupParam = {
                css: "d4-popup d4-popup-rule-" + fieldData.PopupRule + self.getCssClassesForLightbox(fieldData),
                dataToApply: fieldData,
                callback: function () { self.fillLightboxWithContent(fieldData, lightboxContainer); self.initialiseAdLinks(); }
            }
            PubSub.publish(fieldData.PopupEvent, popupParam);
            PubSub.publish(mcWeb.lightbox.events.contentChanged);
            if (isSlideOver) {
                PubSub.subscribe(mcWeb.slideOverBox.events.onHideLightbox, function () {
                    self.slideOverBoxIsOpen = false;
                });
                self.slideOverBoxIsOpen = true;
            }
            else {
                PubSub.subscribe(mcWeb.lightbox.events.onHideLightbox, function () {
                    self.lightBoxIsOpen = false;
                });
                self.lightBoxIsOpen = true;
            }

        }

    };

    this.updateFieldContent = function (fieldData) {
        if (!fieldData.Success)
            return;

        try {
            self.fieldUpdateInProgress = true;

            self.PagingMode = fieldData.PagingMode;

            if (!self.IntersectionObserveAllowed)
                fieldData.AllowDeferred = false;

            if (fieldData.AllowDeferred && fieldData.PopupRule <= 0) {
                for (var i = 0; i < fieldData.Data.length; i++) {
                    var data = fieldData.Data[i];
                    data.added = false;
                }
            }

            if (fieldData.PopupRule <= 0 && (!fieldData.AllowDeferred || (fieldData.PagesRemaining > 0))) {

                self.addRemainingFieldsToDom();//temporary fix for fields that wouldn't load. Needed when the fields added in the loop below adds content that sits between the fields held in memory and themselves.

                for (var i = 0; i < fieldData.Data.length; i++) {
                    var data = fieldData.Data[i];
                    data.targetField = $('#' + data.ClientId);
                    self.addFieldContentToPage(fieldData, data.targetField, data);
                }
            }
            else if (fieldData.PopupRule > 0) {
                var lightboxContainer = $(fieldData.PopupPch);
                if ((fieldData.Data.length > 0)
                    && (lightboxContainer !== undefined))
                    self.preparePopup(fieldData, lightboxContainer);
            }
            else {
                var skipAdd = self.fieldsReadyToLoad.length > 0;

                if (fieldData.Data.length > 0)
                    self.fieldsReadyToLoad.push(fieldData);

                if (!skipAdd) {
                    self.addNextFieldToDom();
                } else {
                    // Fix for EJ 158200, load all remaning fields if there is anything published on the left side
                    if (jQuery("#PageColumnLeft > .PublisherContainer .web-pub-field").length > 0) {
                        self.addRemainingFieldsToDom();
                    }
                }
            }

            if (fieldData.InitializeRoyalSlider) {
                InitializeRoyalSliderInAds();
            }
            if (fieldData.PagesRemaining > 0) {
                self.HasMorePages = true;
            } else if (data !== null && data !== undefined && data.IsProductListing) {
                if (fieldData.PagingMode > 2) {
                    self.hidePaging();
                }
                else if (fieldData.PagingMode === 2 && fieldData.Data !== null && fieldData.Data != undefined && fieldData.Data[0] !== null && fieldData.Data[0] !== undefined && fieldData.Data[0].IsProductListing) {
                    self.hidePaging();
                }
            }

            //scrolling for paging within a field
            if (self.PagingMode >= 3 && !self.scrollEventIsRegistered && fieldData.PagesRemaining > 0) {
                self.scrollEventIsRegistered = true;
                jQuery(window).on("scroll", null, null, self.handleScroll);
            } else if (fieldData.PagesRemaining == 0 && self.PagingMode >= 3 && self.scrollEventIsRegistered && fieldData.Data.length > 0 && !fieldData.AllowDeferred) {
                //no more pages, let's remove the scroll registration
                jQuery(window).off("scroll", null, self.handleScroll);
                self.scrollEventIsRegistered = false;
            }

            var footerlist = document.querySelectorAll('.copyright-container');
            var footer = null;
            if (footerlist.length > 0)
                footer = footerlist[0];

            //scrolling that causes multiple fields to load
            if (footer != null) {
                self.addScrollObserver(footer);
            }

            self.LoadingFromScroll = false;//re-enable auto-scrolling
            if (typeof lipscore !== 'undefined') {
                lipscore.initWidgets()
            }

            if (fieldData.Data.some(x => x.IsProductListing)) {
                self.handleScroll();
            }

        } catch (e) {
            console.log(e);
        }
        finally {
            self.fieldUpdateInProgress = false;
        }
    };

    self.getCssClassesForLightbox = function (fieldData) {
        var classesForFields = "";
        for (var i = 0; i < fieldData.Data.length; i++) {
            var data = fieldData.Data[i];
            if (data.Response.length <= 0) {
                continue;
            }
            classesForFields += " popupfield-" + data.FieldId;
        }
        return classesForFields;
    };
    self.fillLightboxWithContent = function (fieldData, lightboxContainer) {
            getLibrary("renderEngineExtras").then(() => { fillLightboxWithContentExtra(self, fieldData, lightboxContainer); })
      
    };

    self.handleScroll = function (event) {
            getLibrary("renderEngineExtras").then(() => { handleScrollExtra(self, McUtils); })
    };

    

    self.resetAutoScrolledPages = function () {
        self.AutoScrolledPages = 0;
    };


    self.onIntersection = function (entries) {

        if (!self.InitialLoadDone)
            return;

        var entry = null;
        if (entries.length > 0)
            entry = entries[0];
        else {
            return;
        }

        // Are we in viewport?
        if (entry.intersectionRatio > 0) {
            if (!self.HasMorePages) {
                self.addNextFieldToDom();
            }

            if (jQuery(".ajax-field.not-loaded").length == 0) {
                // No more unloaded areas/fields, so stop observing
                self.footerObserver.unobserve(entry.target);
                self.scrollEventFooterIsRegistered = false;
                self.footerObserver = null;
            }

        }

    };

    self.onFieldIntersection = function (entries) {

        var entry = null;
        if (entries.length > 0)
            entry = entries[0];
        else {
            return;
        }

        // Are we in viewport?
        if (entry.intersectionRatio > 0) {
            if (!self.HasMorePages || self.PagingMode <= 2) {
                // Stop observing
                self.fieldScrollObserver.unobserve(entry.target);
                self.scrollEventFieldIsRegistered = false;
                self.fieldScrollObserver = null;

                self.addNextFieldToDom();
            }
        }
    };

    self.addRemainingFieldsToDom = function(){
       self.LoadingFromScroll = true; //pause event-checking on scrolling
        
        if(self.HasMoreFieldsToLoad()) {
            while (self.HasMoreFieldsToLoad()) {
                var myFieldData = self.fieldsReadyToLoad[0];
                var myInnerData = myFieldData.Data.shift();
                if (myFieldData.Data.length == 0) {
                    self.fieldsReadyToLoad.shift();
                }
                myInnerData.targetField = $('#' + myInnerData.ClientId);
                self.addFieldContentToPage(myFieldData, myInnerData.targetField, myInnerData);
            }
        }
        self.LoadingFromScroll = false;
    };

    self.addNextFieldToDom = function (skipIfFieldNotInView) {
        self.LoadingFromScroll = true;//pause event-checking on scrolling
        var fieldWasAdded = false;

        var footer = null;
        if(self.HasMoreFieldsToLoad()) {
            while (self.HasMoreFieldsToLoad()) {

                var myFieldData = self.fieldsReadyToLoad[0];
                
                var myInnerData =  myFieldData.Data[0];
                myInnerData.targetField = $('#' + myInnerData.ClientId);

                if (!skipIfFieldNotInView || McUtils.isElementInView(myInnerData.targetField)) {
                    self.addFieldContentToPage(myFieldData, myInnerData.targetField, myInnerData);
                    fieldWasAdded = true;
                    myInnerData = myFieldData.Data.shift();
                    if (myFieldData.Data.length == 0) {
                        self.fieldsReadyToLoad.shift();
                    }
                }
                if (self.bottomImageToLoad != null && self.bottomImageToLoad != undefined && self.bottomImageToLoad.length > 0) {
                    break;
                }
                if (footer == null) {
                    var footerlist = document.querySelectorAll('.copyright-container');
                    if (footerlist.length > 0)
                        footer = footerlist[0];
                }
                
                if (skipIfFieldNotInView) {
                    self.addScrollObservers(footer);
                    break;
                }
                else if(myInnerData.Response !== null && myInnerData.Response.trim().length > 0 && myInnerData.targetField.height() > 0 && !McUtils.isElementInView(myInnerData.targetField)) {
                    if (self.addScrollObservers(footer))
                        break;
                }
            }
        } else {
            self.bottomImageToLoad = null;
        }

        // Find all fields above the view
        if (!self.finishedLoadingFieldsAbove) {
            var myFieldData, myInnerData;
            ({ myFieldData, myInnerData } = self.popNextFieldAboveView());
            while (myFieldData !== null) {
                myInnerData.targetField = $('#' + myInnerData.ClientId);
                self.addFieldContentToPage(myFieldData, myInnerData.targetField, myInnerData);
                fieldWasAdded = true;
                ({ myFieldData, myInnerData } = self.popNextFieldAboveView());
            }
            self.finishedLoadingFieldsAbove = true;
        }
        

        if (fieldWasAdded) {
            startParaxify('.d4-article-parallax');
        }

        if (self.bottomImageToLoad != undefined &&
            self.bottomImageToLoad != null &&
            self.bottomImageToLoad.length > 0) {
            var currImg = self.bottomImageToLoad[0];
            
            if (currImg != null && currImg.src != undefined && currImg.src != null) {
                var currentImgSrc = self.bottomImageToLoad[0].src;
                var img = new Image();
                img.onload = self.onBottomImgLoaded(myFieldData);//Set up loading of next fields when this image is loaded
                img.onerror = self.onBottomImgLoaded(myFieldData);
                img.src = currentImgSrc;                
                return;
            }
            else if($(currImg).hasClass("inner-content")){
                var bg = $(currImg).css('background-image').trim();
                if(bg.length > 0 && bg !== "none"){
                    //set up loading after this background image has loaded
                    var currentImgSrc= bg.substring(5, bg.length - 2);
                    var img = new Image();
                    img.onload = self.onBottomImgLoaded(myFieldData);
                    img.onerror = self.onBottomImgLoaded(myFieldData);
                    img.src = currentImgSrc;
                    return;
                }
                
            }
        }

        if (!self.HasMoreFieldsToLoad()) {
            startParaxify('.d4-article-parallax');
        }

        self.LoadingFromScroll = false;//un-pause event-checking on scrolling        
    };

    //adds scroll observer on the next field, and optionally on the footer
    //returns true when footer observer was added
    self.addScrollObservers = function (footer) {
        var fieldToObserve = self.findNextFieldToObserve(self.fieldsReadyToLoad);
        if (fieldToObserve != null) {
            var fieldElement = document.getElementById(fieldToObserve.ClientId);
            self.addFieldScrollObserver(fieldElement);
        }
        if (footer == null)
            return false;
        //observe the footer to enable it to trigger adding more content
        if (!McUtils.isElementInView(footer)) {
            self.addScrollObserver(footer);
            return true;
        }
        return false;
    }
    self.onBottomImgLoaded = function(myFieldData){
        return function(){
            var footerlist = document.querySelectorAll('.copyright-container');
            var footer = null;
            if (footerlist.length > 0)
                footer = footerlist[0];

            var loadNextField = false;
            if (McUtils.isElementInView(footer)) {
                loadNextField = true;
            }

            var nextField = self.findNextVisibleField(self.fieldsReadyToLoad);
            if (nextField !== null) {
                loadNextField = true;
            } else {
                var fieldToObserve = self.findNextFieldToObserve(self.fieldsReadyToLoad);
                if (fieldToObserve != null) {
                    var fieldElement = document.getElementById(fieldToObserve.ClientId);
                    self.addFieldScrollObserver(fieldElement);
                }
            }

            if (!loadNextField) {
                if (self.CheckStatistics) {
                    //we have now added the last dynamic field before scrolling, so we log which ones it is so we can learn which fields to load statically later to optimize the page
                    self.postStatistics();
                    self.CheckStatistics = false;
                }

                self.addScrollObserver( footer);
                return;
            }

            self.addNextFieldToDom();
        }
    };

    self.popNextFieldAboveView = function () {

        for (var i = 0; i < self.fieldsReadyToLoad.length; i++) {
            var fieldReadyToLoad = self.fieldsReadyToLoad[i];

            for (var n = 0; n < fieldReadyToLoad.Data.length; n++) {
                var data = fieldReadyToLoad.Data[n];
                var element = document.getElementById(data.ClientId);

                if (element !== null) {
                    if (McUtils.isElementAboveView(element)) {
                        fieldReadyToLoad.Data.splice(n, 1);
                        if(fieldReadyToLoad.Data.length === 0)
                            self.fieldsReadyToLoad.splice(i, 1);


                        return { myFieldData: fieldReadyToLoad, myInnerData: data };
                    }
                }
            }
        }

        return { myFieldData: null, myInnerData: null };
    }

    self.HasMoreFieldsToLoad = function () {
        if (self.fieldsReadyToLoad.length == 0 || self.fieldsReadyToLoad[0].Data.length == 0)
            return false;
        else return true;
    };

    this.findNextVisibleField = function () {

        for (var i = 0; i < self.fieldsReadyToLoad.length; i++) {
            var fieldReadyToLoad = self.fieldsReadyToLoad[i];

            for (var n = 0; n < fieldReadyToLoad.Data.length; n++) {
                var data = fieldReadyToLoad.Data[n];
                var element = document.getElementById(data.ClientId);

                if (element !== null) {
                    if (McUtils.isElementInView(element))
                        return data;
                }
            }
        }

        return null;
    };

    this.findNextFieldToObserve = function () {

        for (var i = 0; i < self.fieldsReadyToLoad.length; i++) {
            var fieldReadyToLoad = self.fieldsReadyToLoad[i];

            for (var n = 0; n < fieldReadyToLoad.Data.length; n++) {
                var data = fieldReadyToLoad.Data[n];
                var element = document.getElementById(data.ClientId);

                if (element !== null) {
                    if (McUtils.isElementBelowView(element))
                        return data;
                }
            }
        }

        return null;
    };

    this.addScrollObserver = function (footer) {
        if (!self.scrollEventFooterIsRegistered && (self.HasMorePages || self.HasMoreFieldsToLoad())) {
            self.scrollEventFooterIsRegistered = true;
            var config = {
                // If the image gets within 50px in the Y axis, start the download.
                rootMargin: '0px 0px',
                threshold: 0.01
            };

            // The observer for the images on the page
            self.footerObserver = new IntersectionObserver(self.onIntersection, config);

            self.footerObserver.observe(footer);
        }
    };

    this.addFieldScrollObserver = function (field) {
        if (!self.scrollEventFieldIsRegistered) {
            self.scrollEventFieldIsRegistered = true;
            var config = {
                // If the image gets within 50px in the Y axis, start the download.
                rootMargin: '0px 0px',
                threshold: 0.01
            };

            // The observer for the images on the page
            self.fieldScrollObserver = new IntersectionObserver(self.onFieldIntersection, config);

            self.fieldScrollObserver.observe(field);
        }
    };


    //todo: move to script-block from "RenderFilter", only needed when _app.Setup.GetSettingValue(CategoryName.MulticaseAttributes, SettingName.MaxDefaultVisibleAttributeValues) == "0")
    this.toggleIconAttributeValues = function (target) {
        $('#AttributeListBox .collapse.event-not-ready').on('shown.bs.collapse', function () {
            $(this).parent().find(".glyphicon-plus").removeClass("glyphicon-plus").addClass("glyphicon-minus");            
        }).on('hidden.bs.collapse', function () {
            $(this).parent().find(".glyphicon-minus").removeClass("glyphicon-minus").addClass("glyphicon-plus");
        });
        $('#AttributeListBox .collapse').removeClass("event-not-ready");
    };

        this.searchAtttributes = function (target) {
            getLibrary("renderEngineExtras").then(() => { searchAtttributesExtra(target); });
        };

        this.preventDefaultOnEnter = function (event) {
            if (event.keyCode == 13) {
                event.preventDefault();
                return false;
            }
        };

    //todo: move to secondary file?
        this.updateFilterContent = function (filterData) {
            getLibrary("renderEngineExtras").then(() => { updateFilterContentExtra(self, filterData); });
       
    };
    this.urlParamsFilterIsValidFor = null;


    this.delayLoadFilterParent = null;
    this.delayLoadFilterManufacturerId = 0;
    this.delayLoadFilterLoaded = false;
    
    this.unhideProductMenu = function () {
        var menu = jQuery("div.ProductMenu");
        if (menu.length > 0) {
            menu.removeClass("hidden");
        }
    };
    
    this.selectLayoutClick = function (event) {

        var dummyBarn = jQuery(".dummy-element *");

        dummyBarn.prop("onclick", null);
        dummyBarn.removeAttr('href');
        dummyBarn.off();

        event.stopImmediatePropagation();

        mcWeb.publisherAdminMenu.callLayoutAction("Select", event);
        return false;
    };

    this.cancelBubble = function (e) {
        var evt = e ? e : window.event;
        if (evt.stopPropagation) evt.stopPropagation();
        if (evt.cancelBubble != null) evt.cancelBubble = true;
    };

  
    self.updateVariantMatrix = function (data) {
        getLibrary("renderEngineExtras").then(() => { updateVariantMatrixField(data); loadLightboxFields(self); });
       
    };

    //todo: move to another file, getlibrary call from onClick event in ToggleDefaultKontaktDisplayElement
        self.toggleDefaultContact = function (e) {
            self.service.GetAction("ToggleDefaultCustomer").then(function (e) {
                var delayInMilliseconds = 400;

                setTimeout(function () {
                    location.reload();
                }, delayInMilliseconds);
            });
        };

    //todo: move to another file, getLibrary call from SelectDefaultKontaktDialog.ascx
        self.SetPrimaryOrSecondaryDefaultContact = function (preferSecondaryDefaultContactId) {
            self.service.GetAction("SetPrimaryOrSecondaryDefaultContact", { preferSecondaryDefaultContactId }).then(function (result) {

                var delayInMilliseconds = 200;
                setTimeout(function () {
                    location.reload();
                }, delayInMilliseconds);

                window.localStorage.hideSelectDefaultKontaktPopup = 'true';
            })
        };

})(jQuery);

if(document.observe === undefined){
    document.observe = function (name, myfunction) {
        if(name === "dom:loaded")
            jQuery.ready(myfunction);
    // Override document.observe to not give errors from Gaia
    };
}


jQuery.fn.outerHTML = function () {
    return jQuery('<div />').append(this.eq(0).clone()).html();
};

window.mcWeb.ajaxRenderEngine = AjaxRenderingEngine;
window.mcWeb.ajaxRenderEngine.events = AjaxRenderingEngineEventTypes;